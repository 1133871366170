<template>
	<page>
		<module enable-flex>
			<c-form ref="form" vertical style="padding-bottom: 0;">
				<c-form column="2" vertical unit-width="400" style="padding: 0;">
					<c-form-item label="选择报工产品" required>
						<c-select placeholder="可输入搜索(名称、编号）" :clear="false" async @search="searchProduct" @change="selectProduct"></c-select>
					</c-form-item>
					<c-form-item label="报工日期" required>
						<c-datetime type="date" name="work_date"></c-datetime>
					</c-form-item>
				</c-form>
				<c-form vertical unit-width="100%" style="padding: 0;">
					<c-form-item label="报工详情" required>
						<p v-if="!product" style="padding-top:10px;padding-left:20px;color:#666;">请先选择需要报工的产品</p>
						<p v-else-if="procedure.length == 0" style="padding-top:10px;padding-left:20px;color: #ee9900;;">当前报工产品未配置工艺路线，无法报工</p>
					</c-form-item>
				</c-form>
			</c-form>
			
			<div ref="procedure" class="procedure-scroll flex-grow">
				<div class="procedure-list flex-center-cross">
					<template v-for="(group, i) in procedure">
						<i v-if="i > 0" class="procedure-angle tf">&#xed7a;</i>
						<dl :key="i" class="procedure flex-column">
							<dt class="flex-between-center">
								<p>{{group.name}}</p>
								<span>小计：{{groupTotal(group.list)}}</span>
							</dt>
							<dd class="flex-grow flex-column">
								<ul>
									<li v-for="(item, j) in group.list" :key="item.index" class="flex-center-cross">
										<c-select v-model="item.id" class="input" :clear="false" async @search="searchPerson(group, arguments[0], arguments[1])" @change="insertItem(group)"></c-select>
										<c-input v-model="item.num" class="input" type="number" :disabled="!item.id" range="0,9999999" placeholder="数量"></c-input>
										<a v-if="group.list.length > 1 && item.id" class="delete flex-center" @click="group.list.splice(j, 1)"><i class="tf tf-trash"></i></a>
									</li>
								</ul>
							</dd>
						</dl>
					</template>
				</div>
			</div>

			<template slot="button">
				<c-button color="main" size="form" @click="submit">确认提交</c-button>
			</template>
		</module>
	</page>
</template>

<script>
	import {cryptoJs} from '@deanwu/vue-component-library'
	import {mapState} from 'vuex'
	import {debounce} from '@deanwu/vue-component-library'

	export default {
		name: 'm_worke_report',
		
		data() {
			return {
				product: '',
				itemIndex: 0,
				procedure: []
			}
		},
		
		computed: {
			...mapState(['sizeConfig']),
			
			groupTotal() {
				return list => {
					let num = 0;
					list.forEach(item => {
						num += parseInt(item.num) || 0;
					});
					return num;
				}
			}
		},
		
		watch: {
			product() {
				this.getProcedureList();
			}
		},
		
		mounted() {
			this.$refs.form.set({
				work_date: new Date().format('yyyy-MM-dd')
			});
			this.$refs.procedure.addEventListener('wheel', e => {
				e.preventDefault();
				const delta = e.deltaX || e.deltaY;
				this.$refs.procedure.scrollLeft += delta;
			});
		},
		
		methods: {
			searchProduct: debounce(function(keyword, resolve) {
				this.request({
					url: '/product/list',
					data: {
						type: 1,
						keyword: keyword,
						pageIndex: 1,
						pageSize: 20
					},
					success: data => {
						resolve(data.map(item => {
							return {
								name: item.product_name,
								value: item.id,
								data: item
							}
						}))
					}
				})
			}, 200, false),
			
			selectProduct(data) {
				this.product = data;
			},
			
			getProcedureList() {
				if(!this.product.data.route_id){
					this.$message({
					    message: '当前产品未配置工艺路线，无法完成报工'
					});
					this.procedure = [];
					return ;
				}
				this.request({
					url: '/process_route/processes_list',
					data: {
						route_id: this.product.data.route_id
					},
					success: data => {
						this.procedure = data.map(item => {
							let group = {
								name: item.name,
								id: item.route_id,
								processes_id: item.processes_id,
								list: []
							}
							this.insertItem(group);
							return group;
						});
					}
				})
			},
			
			searchPerson(group, keyword, resolve) {
				this.request({
					url: '/sys_user/list',
					data: {
						user_realname: keyword,
						user_type: 3,
						pageIndex: 1,
						pageSize: 20
					},
					success: data => {
						resolve(data.filter(item => {
							return !group.list.find(temp => temp.id == item.id);
						}).map(item => {
							return {
								name: item.user_realname,
								value: item.id
							}
						}));
					}
				});
			},
			
			insertItem(group) {
				if (!group.list.find(item => !item.id)) {
					group.list.push({
						index: ++this.itemIndex,
						id: '',
						num: ''
					})
				}
			},
			
			submit() {
				this.$refs.form.submit({
					url: '/worker_record/add',
					rule: {
						product: [{
							type: 'function',
							fun: () => {
								return this.product;
							},
							message: '请选择报工产品'
						}],
						work_date: '请选择报工日期',
						record_list: [{
							type: 'function',
							fun: () => {
								return this.procedure.find(item => {
									return item.list.find(item => item.id && item.num);
								});
							},
							message: '请填写报工详情'
						}]
					},
					dataFormatter: data => {
						data.product_id = this.product.value;
						data.record_list = [];
						this.procedure.forEach(group => {
							group.list.forEach(item => {
								if (item.id && item.num) {
									data.record_list.push({
										processes_id: group.processes_id,
										yg_id: item.id,
										product_count: item.num
									})
								}
							});
						});
						return data;
					},
					success: () => {
						this.$message({
							message: '提交成功'
						});
						this.$bus.$emit('workerRecordList.update');
						this.redirectTo('/worker_record_list')
					}
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.procedure {
		box-sizing: border-box;
		height: calc(100% - 20px);
		border: 1px solid #eee;
		margin-bottom: 20px;
		
		&-scroll {
			overflow: auto;
		}
		
		&-list {
			float: left;
			height: 100%;
			padding: 0 20px;
		}
		
		&-angle {
			width: 40px;
			font-size: 20px;
			text-align: center;
			color: #ccc;
		}
		
		dt {
			height: 40px;
			border-bottom: 1px solid #eee;
			padding: 0 12px 0 15px;
			background: #FAFAFA;
			
			p {
				font-size: 16px;
				font-weight: bold;
			}
			
			span {
				font-size: 12px;
				color: #666;
			}
		}
		
		dd {
			li {
				height: 42px;
				padding: 0 10px;
				
				.input {
					width: 110px;
					height: 30px;
					margin-right: 8px;
				}
				
				.delete {
					width: 30px;
					height: 30px;
					border-radius: 100%;
					color: #999;
					background: #F9F9F9;
					
					&:hover {
						color: #fff;
						background: #EB0000;
					}
				}
			}
		}
	}
</style>